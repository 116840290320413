@import '../../Sass/mixins';
@import '../../Sass/variables';

.content {
    @include flex-container;

    & > li {
        padding: 0 2rem;
        list-style-type: none;
        font-size: 1.5rem;
        line-height: 2.5rem; /* added for better readability */
    }
}

.footer {
    @include flex-container-col; 
    padding: $padding-section;
    border-top: 1px solid $color-gray-2; /* added for visual separation */
    text-align: center; /* added for center alignment */
}

.logo {
    height: 4rem;
    width: 4rem;
    margin: $margin-sm 0;
}

.wrapper {
    background-color: $color-white-1;
}

/* Media Queries */
@media screen and (min-width: 2560px){
    .content {
        @include flex-container;
    
        & > li {
            font-size: 2.5rem;
            line-height: 4rem; /* added for better readability */
        }
    }

    .footer {
        border-top: none; /* removed for better aesthetics */
    }

    .logo {
        height: 11rem;
        width: 11rem;
        margin: $margin-sm 0;
    }
}

@media screen and (max-width: 1200px){
    .footer {
        padding: $padding-section-md;
    }
}

@media screen and (max-width: 767px){
    .footer {
        padding: $padding-section-sm;
    }
}

@media screen and (max-width: 500px) {
    .footer {
        padding: $padding-section-xs;
    }

    .content {
        & > li {
            padding: 0 1rem;
            font-size: 1rem;
            line-height: 1.5rem; /* added for better readability */
            margin-bottom: 1rem; /* added for better spacing */
        }
    }

    .logo {
        height: 4rem;
        margin: 1rem 0;
    }

    .footer {
        text-align: center; /* added for center alignment */
    }

    .copyright {
        font-size: 1rem;
        line-height: 1.5rem; /* added for better readability */
    }
}

 