@import '../../Sass/variables';
@import '../../Sass/mixins';

.header {
	font-familY: $header-font;
	text-align: center;
	font-size: 4rem;
	margin-bottom: 1rem;
}

.main__container {
	height: 10vh;
	// width: 100%;
 
	background-color: #f3f3f3;
	// display: flex;
    // position: relative;
    // justify-content: center;
    // align-items: center;
    // flex-direction: row;
 
}

 