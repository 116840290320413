.logo {
  height: 5rem;
  width: 5rem;
  justify-self: start;
  cursor: pointer;
  margin-left: 2rem;
}

/* .logo:hover {
  transform: rotate(1080deg);
  transition: all 2s;
  animation-fill-mode: forwards;
} */

.menu-icon {
  display:none;
  height: 3rem;
  width: 3rem;
}

.close {
  z-index: 20000000;
  height: 3rem;
  width: 3rem;
  transform: translate(-0.2rem, 0.3rem);
}

.nav {
  display: grid;
  grid-template-columns: repeat(5, auto);
  /* grid-gap: 10px; */
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.navbar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-size: 1.5rem;
  height: 8rem;
  position: relative;
}

.nav-links {
  color: #fff !important;
  position: relative;
  text-decoration: none;
  /* padding: 0.5rem 1rem; */
  margin: 1rem;
}

.nav-links:hover {
  cursor: pointer;
}

.nav-links::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f0cf16;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  color: white !important;
}

.nav-links:hover::before {
  transform: scaleX(1);
}

/* ////////MEDIA QUERIES///////////////////////// */

@media screen and (min-width: 2560px) {
  .nav-links {
    font-size: 4rem;
  }

  .logo {
    height: 6rem;
    width: 6rem;
  }
}

@media screen and (max-width: 2400px) {
  
  .nav-links {
    font-size: 3rem;

  }
}

@media screen and (max-width: 1740px) {
  .nav-links {
    font-size: 2.5rem;

  }
}

@media screen and (max-width: 1440px) {
  .nav-links {
    font-size: 2rem;

  }
}

@media screen and (max-width: 960px) {
  .nav-links {
    font-size: 5rem;
    transform: translateY(9rem);
    margin-top: 2rem;
  }

  .navbar-items {
    position: relative;

  }

  .nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 110vh;
    position: fixed;
    top: -2px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav.active {
    backdrop-filter: blur(1rem);
    background-color: rgba(0,0,0,0.8);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-items: center;
    justify-content: flex-start;
    background-image: url('../../../Images/7-edited.webp');
    background-position: center 15px; 
    background-repeat: no-repeat;
    background-size: 6rem;
     z-index: 200;
  }

  .nav-links {
    text-align: center;
    display: flex;
  }

  .nav-links:hover {
    border-radius: 6px;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 40%);
  }

  .menu-icon {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    transform: translate(-0.3rem, 0.7rem);
    height: 3rem;
    cursor: pointer;
    z-index: 250;
  }

  .logo {
    margin-left: 0;
    transform: translateY(-7px);
  }
}

@media screen and (max-width: 960px) {
  .nav-links {
    font-size:5rem;
    margin-top: 0.75rem
  }

  .nav {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .logo {
    height: 3rem;
    width: 3rem;
    transform: translateY(0px);
  }

  .menu-icon {
    height: 2rem
  }

  .close {
    height: 2rem
  }

  .nav-links {
    font-size:3.5rem;
    margin-top: 0.5rem
  }

  .nav {
    width: 100%;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(0.9);
  }
}
.mypage-alo-phone {
  position: fixed;
  right: 30px;
  bottom: 80px;
  visibility: visible;
  background-color: transparent;
  width: 110px;
  height: 110px;
  cursor: pointer;
  z-index: 200000 !important;
}
.mypage-alo-ph-img-circle {
  width: 30px;
  height: 30px;
  top: 43px;
  left: 43px;
  position: absolute;
  background: url(https://i.imgur.com/J9AXAq7.png) no-repeat center center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.7;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background-color: #ff992c;
  background-size: 70%;
  -webkit-animation: pulse 01s infinite;
}
.mypage-alo-ph-circle-fill {
  width: 60px;
  height: 60px;
  top: 28px;
  left: 28px;
  position: absolute;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  background-color: rgba(241, 168, 5, 0.5);
  opacity: 0.75 !important;
  -webkit-animation: pulse 1s infinite;
}
.mypage-alo-ph-circle {
  width: 90px;
  height: 90px;
  top: 12px;
  left: 12px;
  position: absolute;
  background-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, 0.4);
  opacity: 0.1;
  border-color: #ffb56b;
  opacity: 0.5;
}
.social {
  display: inline-block;
  margin: 0 10px;
  color: #333;
  font-size: 30px;
  transition: all 0.2s ease-in-out;
}

.social:hover {
  color: #0077B5;
  transform: translateY(-3px);
}

.fa-facebook-f {
  color: #3b5998;
}

.fa-instagram {
  color: #e1306c;
}
