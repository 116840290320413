.btn {
  display: inline-block;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
  border: 2px solid #2c3e50;
  color: #2c3e50;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn:hover {
  background-color: #2c3e50;
  color: #fff;
}


button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

@media screen and (min-width: 2560px) {
  .btn {
    width: 127rem;
    font-size: 3rem;
  }
}

@media screen and (max-width: 1200px) {
  .btn {
    font-size: 1.5rem;
    padding: 9px 4rem;
  }
}

@media screen and (max-width: 900px) {
  .btn {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .btn {
    font-size: 1.2rem;
    margin-top: 0.1rem;
    width: 25rem;
  }
}
