@import './Sass/variables';

.app {
  background-color: #f3f3f3;
  font-family: $body-font;
  height: 100%;
  // overflow-x: hidden;
}



