 
      #contact {
        max-width: 400px;
        width: 100%;
      }
  
      form {
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: #f2f2f2;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      }
  
      label {
        margin-top: 10px;
        margin-bottom: 5px;
        font-weight: bold;
      }
  
      input, textarea {
        padding: 10px;
        border-radius: 3px;
        border: 1px solid #ccc;
      }
  
      button {
        margin-top: 10px;
        padding: 10px;
        background-color: #000000;
        color: #fff;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        font-weight: bold;
      }
   