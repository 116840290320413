$header-font: "Russo One","Futura", "Impact", sans-serif;
$body-font: 'Poppins', sans-serif;
$font-size-body: 1.5rem;
$font-size-body-sm: 1.2rem;

$color-main: #247ba0;
$color-light-blue: #1FB2B8;
$color-medium-blue: #1F62B8;

$color-secondary-1: #ffd81b;
$color-secondary-2: #f0cf16;

$color-tertiary: #1DAD8A;

$color-gray: #f3f3f3;
$color-gray-2: #eee;
$color-gray-3: #ccc;

$color-white-1: #fbfbfb;
$color-black: #1b1b1b;

$margin-lg: 5rem;
$margin-md: 4rem;
$margin-sm: 2rem;

$padding-section: 13rem 0;
$padding-section-md: 10rem 0;
$padding-section-sm: 9rem 0;
$padding-section-xs: 5rem 0;

$border-line: 1px solid $color-gray-3;


