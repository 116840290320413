@import '../../Sass/variables';
@import '../../Sass/mixins';

.banner {
    @include flex-container-col;
    justify-content: center;
    padding: 3rem 0;
}

.btn {
    display: inline-block;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
    border: 2px solid #2c3e50;
    color: #2c3e50;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .btn:hover {
    background-color: #2c3e50;
    color: #fff;
  }
  

.details {
    padding: 2rem 0;
    color: $color-white-1;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    font-family: $body-font;
}

.line {
    color: $color-white-1;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    font-family: $body-font;
}

.marker {
    height: 2rem!important;
    width: 2rem;
}

.quote {
    color: $color-white-1;
    text-align: center;
    font-size: 4rem;
    margin-bottom: 3.5rem;
    font-weight: 700;
    font-family: $header-font;
}

.wrapper {
    height: auto;
    width: 100%;
    background-color: $color-medium-blue;
}

////MEDIA QUERIES BASED ON WIDTH ///////////////////////
@media screen and (min-width: 1900px) {
    .line {
        font-size: 3.5rem;
    }

    .details {
        font-size: 3.5rem;
    }

    .quote {
        font-size: 4rem;
    }
}

@media screen and (min-width: 1200px) {
    .quote {
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 767px) {
    .btn {
        font-size: 1.5rem;
        padding: 4px 4rem;
        width: 27.5rem;
    }

    .line {
        font-size: 1.7rem;
    }

    .details {
        font-size: 1.7rem;
    }

    .quote {
        font-size: 4rem;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 550px) {
    .line {
        font-size: 1.4rem;
    }

    .details {
        font-size: 1.4rem;
        padding: 1rem 0;
    }

    .quote {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    .btn {
    font-size: 1.5rem;
    padding: 2px 4rem;
    width: 21rem;
    }
}

@media screen and (max-width: 350px) {
    .btn {
        font-size: 1.5rem;
        padding: 2px 4rem;
        width: 21rem;
        }
}

 