@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Russo+One&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html {
  font-size: 10px;
  /* overflow-x: hidden; */
  /* min-height: -webkit-fill-available; */
  min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
  /* overflow-y: scroll;
  position: relative; */
  /* min-height: 100%; */
  /* height: 100%;
  min-height: -webkit-fill-available; */
  min-height: 100%;
  min-height: -webkit-fill-available;
}

body > div {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.leaflet-container {
  width:50vw;
  height: 100%;
  border-radius: 1rem;
  box-shadow: 5px 3px 3px rgba(0, 0, 0, 0.6);
  width: 95%;
  z-index: 2;
}

@media only screen and (max-width: 576px) {
  .leaflet-container{
    width: 80vw;
    height: 50vh;
    border-radius: 1rem;
  }
}

@media only screen and (max-width: 450px) {
  .leaflet-container{
    width: 80vw;
    height: 34vh;
    border-radius: 1rem;
  }
}

