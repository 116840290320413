/* Import Sass variables and mixins */
@import "../../Sass/variables";
@import "../../Sass/mixins";

/* Styles for the features section */
.features {
  display: flex;
  position: relative;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  padding: $padding-section;

  /* Styles for each feature card */
  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;

    /* Styles for the feature icons */
    &__icons {
      height: 5rem;
      width: 7rem;
      margin-bottom: 2rem;
    }

    /* Styles for the feature text */
    &__text {
      padding: 0 1.5rem;
    }
  }
}

/* Button styles for the "Find Out More" button */
.findOut {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: $color-secondary-1;
  color: $color-black;
  border-radius: 3px;
  margin: 2rem 0;
  padding: 0 2rem;
  border: none;

  /* Styles on hover */
  &:hover {
    background-color: $color-secondary-2;
  }
}

/* Styles for the first section */
.firstsection {
  background-color: #f3f3f3;
}

/* Styles for the header text */
.header {
  text-align: center;
  font-weight: bolder;
  overflow: hidden;
  white-space: nowrap;

  /* Media query for larger screens */
  @media only screen and (min-width: 2560px) {
    font-size: 3rem;
  }
}

/* Media query for medium-sized screens */
@media screen and (max-width: 1200px) {
  .features {
    padding: $padding-section-md;
  }
}

/* Media query for small screens */
@media screen and (max-width: 900px) {
  .features {
    padding: $padding-section-sm;
  }
}

/* Media query for extra small screens */
@media only screen and (max-width: 500px) {
  .features__text {
    width: 30rem;
  }
}

/* Media query for tiny screens */
@media only screen and (max-width: 300px) {
  .features__text {
    width: 23rem;
  }
}
 