@import '../../../Sass/mixins';
@import '../../../Sass/variables';

.backdrop {
    position: fixed; 
    width: 100%; 
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 3; 
    cursor: pointer; 
}

.flex {
    &__container {
        @include flex-container-col;
        justify-content: space-around;
        padding: 10rem;
    
        & > label {
            transform: translateX(-4.3rem);
        }
    }

    &__row{
        @include flex-container;
    }
}

h3 {
    text-align: left;
    margin: 0;
}

.logo {
    position: absolute;
    transform: translateY(-13rem);
    height: 18rem;
    width: 18rem;
    z-index: 6;
    &__container {
        @include flex-container;
        justify-content: center;
    }
}

.modal{
    &__backdrop { 
        position: fixed; 
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 3;
        border-radius: 6px 0 0 6px;
    }

    &__header  {
        position: absolute;
        color: $color-white-1;
        transform: translate(2rem,-50.5rem);
        z-index: 4;
        font-weight: bolder;
        font-family: $header-font;
    }

    &__line {
        position: absolute;
        color: $color-white-1;
        transform: translate(-9.1rem,-45rem);
        z-index: 4;
        font-size: 1.3rem;
    }

    &__pic {
        width: 40rem;
        border-radius: 6px 0 0 6px;
        height: 53rem;
    }

    &__window {
        @include position-absolutely;
        position: fixed;
        z-index: 900;
        color: $color-black;
    }
}

.phone {
    display: flex;
    position: relative;
    font-size: 1.5rem;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    color: $color-white-1;
    height: 1.5rem;
    margin: 1.8rem 0;

    &__container {
        position: absolute;
        padding: 6rem;
        transform: translate(-4rem,-44.2rem);
        z-index: 4;
        margin-bottom: 4rem;
    }

    &__icon {
        margin-right: 1rem;
        height: 2rem;
        font-weight: bold;
    }
}

///////ACCESSIBILITY MEDIA QUERY ZOOM IN 200%
@media screen and (max-height: 2000px) and (max-width: 1000px){
    .modal__branding {
        display: none!important;
    }
}

@media screen and (max-width: 850px){
    .modal__branding{
        display: none;
    }
}

@media screen and (min-width: 2560px){
    .logo {
        transform: translateY(-16rem);
        height: 24rem;
        width: 24rem;
    }
    
    .modal__pic {
        width: 60rem;
        border-radius: 6px 0 0 6px;
        height: 73rem;
    }

    .modal__header {
    font-size: 5.5rem !important;
    transform: translate(2rem, -69.5rem);
    }

    .modal__line {
        transform: translate(-11.5rem, -62rem);
        font-size: 2.1rem;
    }

    .modal__backdrop {
        width: 53%;
    }

    .phone__container {
        transform: translate(-4rem, -55.2rem);
    }

    .phone {
        font-size: 2rem;
    }
}



@media screen and (min-width: 1501px){
    .modal__line {
        width: 70%;
    }
}



@media screen and (max-width: 901px){
    .modal__line {
        width: 70%;
        // transform: translate(-9.4rem,-45rem);
    }
}

